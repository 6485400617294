.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 150px;
}

.navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.navbar-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.logo {
    height: 220px;
}

.menu-toggle {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
}

.menu-items {
    display: flex;
    align-items: center;
    gap: 20px;
}

.menu-items a,
.menu-items .contact-button,
.menu-items .language-select,
.menu-items .location-link {
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    margin-right: 25px;
    font-family: 'Times New Roman', Times, serif;
}

.contact-button {
    background-color: black;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    text-decoration: none;
}

.location-link {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 1rem;
}

.location-icon {
    margin-right: 5px;
}

.language-select {
    border: none;
    background: none;
    font-size: 1rem;
    cursor: pointer;
}

@media (max-width: 768px) {


    .menu-toggle {
        display: block;
        margin-right: 40px;
        color: white;
    }

    .menu-items {
        display: none;
    }

    .menu-items.open {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: white;
        width: 100%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        padding: 20px;
    }

    .mobile-menu {
        position: fixed;
        top: 0;
        right: 0;
        width: 80%;
        height: 100%;
        background-color: white;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        padding: 20px;
    }

    .close-menu {
        font-size: 2rem;
        background: none;
        border: none;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .mobile-menu-items {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 60px;
    }

    .mobile-menu-items a,
    .mobile-menu-items .language-select,
    .mobile-menu-items .location-link {
        text-decoration: none;
        color: #333;
        font-size: 1.5rem;
    }

    .location-link {
        display: flex;
        align-items: center;
        color: #333;
        font-size: 1.5rem;
    }

    .location-icon {
        margin-right: 5px;
    }

    .mobile-menu-items .contact-button {
        text-decoration: none;
        color: white;
        font-size: 1.5rem;
    }

    .navbar.scrolled {
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        max-height: 100px;
    }
}