/* ProductModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    text-align: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.modal-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

/* Media queries for mobile sizes */
@media (max-width: 768px) {
    .modal-content {
        padding: 15px;
        max-width: 95%;
        max-height: 95%;
    }

    .close-button {
        font-size: 18px;
    }

    .modal-image {
        margin-bottom: 15px;
    }
}

@media (max-width: 480px) {
    .modal-content {
        padding: 10px;
        max-width: 100%;
        max-height: 100%;
    }

    .close-button {
        font-size: 16px;
        top: 5px;
        right: 5px;
    }

    .modal-image {
        margin-bottom: 10px;
    }
}