body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

nav {
  background-color: #333;

}

nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}



nav a {
  color: white;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

h1 {
  color: #333;
}

.content {
  padding-bottom: 0%;
  padding-left: 0%;
  padding-right: 0%;
  margin-right: 0%;
  /* Space for the fixed footer */
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
    /* Adjust font size for better readability on mobile */
    padding: 0px;
    /* Adjust padding for better layout on mobile */
  }
}