/* Default styles for larger screens */
.cinnamon-top-section {
    position: relative;
}

.top-image {
    width: 100%;
    height: 500px;
}

.cinnamon-top-text {
    position: absolute;
    bottom: 40px;
    left: 40px;
    color: white;
    padding: 10px;
}

.cinnamon-top-text h1 {
    margin: 0;
    font-size: 4em;
    color: #FFFFFF;
    font-family: 'Times New Roman';
}

.cinnamon-top-text p {
    margin: 5px 0 0;
    font-size: 1.8em;
}

/* Styles for the history section */
.history-section {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin-top: 30px;
    justify-content: space-between;
}

.history-text {
    flex: 1;
    padding-right: 20px;
}

.history-text h1 {
    font-size: 3em;
    margin-bottom: 20px;
    font-family: 'Times New Roman';
}

.history-text ul {
    list-style-type: none;
    padding: 0;
}

.history-text li {
    margin-bottom: 10px;
}

.history-text li ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #808080;
}

.history-text li strong {
    display: block;
    margin-bottom: 5px;
    font-size: 1.2em;
}

.history-image {
    flex: 1;
    max-width: 500px;
}

.history-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* New styles for the information section */
.info-section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 20px;
    border: 2px solid #ccc;
    margin-bottom: 20px;
    background-color: #BDB76B;
}

.info-section h1 {
    text-align: left;
    font-size: 3em;
    margin-left: 20px;
    font-family: 'Times New Roman';
    order: 1;
}

.cinnamon-info-section {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;
    margin-top: 40px;
    order: 2;
}

.info-image {
    flex: 1;
    padding-right: 20px;
}

.info-image img {
    width: 100%;
    height: 1250px;
    border-radius: 10px;
    margin-left: 20px;
    margin-top: 10px;
}

.info-text {
    flex: 1;
    padding-left: 20px;
}

.info-text h2 {
    font-size: 2em;
    margin-bottom: 10px;
    font-family: 'Times New Roman';
}

.info-text ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #808080;
}

.info-text li {
    margin-bottom: 10px;
}

.benificts-section {
    display: flex;
    padding: 20px;
    margin-top: 20px;
    border: 2px solid #ccc;
    margin-bottom: 20px;
    background-color: #BDB76B;
    height: 100px;
}

.benifts {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;

}

.benifts h1 {
    text-align: left;
    font-size: 3em;
    margin-left: 20px;
    font-family: 'Times New Roman';
    order: 1;
    text-align: center;
}

.video-section {
    margin-top: 20px;
    padding-bottom: 60px;
}

video {
    margin-top: 20px;
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
    .cinnamon-top-section {
        position: relative;
    }

    .top-image {
        height: 300px;
        width: 100%;

    }

    .cinnamon-top-text {
        bottom: 20px;
        /* Adjust position */
        left: 20px;
        /* Adjust position */
    }

    .cinnamon-top-text h1 {
        font-size: 2.5em;
        /* Reduce font size */
    }

    .cinnamon-top-text p {
        font-size: 1em;
        /* Reduce font size */
    }

    .history-section {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        padding: 10px;
        margin-top: 10px;
    }

    .history-text h1 {
        font-size: 2.2em;
        margin-bottom: 20px;
        font-family: 'Times New Roman';
    }

    .history-text {
        padding: 10px;
        max-width: 100%;
    }

    .history-image {
        max-width: 600px;
        margin-left: 80px;
    }

    .history-text {
        margin-bottom: 20px;
    }

    .info-section {
        padding: 10px;
        margin-top: 10px;
    }

    .info-section h1 {
        font-size: 2em;
    }

    .cinnamon-info-section {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 0%;
    }

    .info-image img {
        height: auto;
        max-width: 100%;
        margin-right: 30px;
    }

    .info-image {
        margin-right: 25px;
        margin-top: 20px;
    }

    .info-text {
        padding-left: 10px;
    }

    .benifts {
        padding: 10px;
    }

    .benifts h1 {
        font-size: 2em;
        margin-left: 0;
        text-align: center;
    }

    .video-section {
        padding-bottom: 30px;
        width: 90%;
        margin-left: 20px;
    }
}