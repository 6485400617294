.home-top-section {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
}

.animated-image {
    position: absolute;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: 0;
    animation: fadeInOut 16s infinite;
}

.animated-image:nth-child(1) {
    animation-delay: 0s;
}

.animated-image:nth-child(2) {
    animation-delay: 4s;
}

.animated-image:nth-child(3) {
    animation-delay: 8s;
}

.animated-image:nth-child(4) {
    animation-delay: 12s;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    12.5% {
        opacity: 1;
    }

    37.5% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

/* Styling for the text overlay */
.text-overlay {
    position: absolute;
    bottom: 20px;
    /* Distance from the bottom of the image */
    left: 40%;
    transform: translateX(-50%);
    text-align: left;
    color: white;
    /* Text color to ensure visibility over the images */
    padding: 0;
    /* Remove padding to avoid the box effect */
    margin-bottom: 30px;
}

.text-overlay h1 {
    font-size: 4rem;
    /* Increase font size for heading */
    margin: 0 0 10px;
    /* Margin between the heading and paragraph */
    color: #FFFFFF;
    font-family: 'Times New Roman', Times, serif;
}

.text-overlay p {
    font-size: 1.6rem;
    /* Increase font size for paragraph */
    margin: 0;
    color: #FFFFFF;
    font-family: 'Times New Roman', Times, serif;
}

@media (max-width: 768px) {
    .home-top-section {
        height: 100vh;
    }

    .image-container,
    .animated-image {
        width: 100vw;
        height: 100vh;
    }

    .text-overlay {
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);

    }

    .text-overlay h1 {
        font-size: 2.5rem;

        margin-top: 0%;
        padding-top: 0%;
        text-align: center;
    }

    .text-overlay p {
        font-size: 1.2rem;

        text-align: center;
    }
}