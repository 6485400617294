/* Define the pop-up animation */
@keyframes popup {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.about-page-container {
    padding-bottom: 0%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-bottom: 0%;
}

.about-content-section,
.journey-section,
.story-section {
    flex: 1;
}



/* Base class for animating elements */
.animate-popup {
    opacity: 0;
    animation: popup 0.6s ease-out forwards;
}

/* Add specific animation delays for different elements */
.left-column.animate-popup {
    animation-delay: 0.2s;
}

.right-column.animate-popup {
    animation-delay: 0.4s;
}

.journey-section.animate-popup {
    animation-delay: 0.6s;
}

.story-section.animate-popup {
    animation-delay: 0.8s;
}

/* About Top Section Styles */
.about-top-section {
    position: relative;
}

.about-top-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.about-top-text {
    position: absolute;
    bottom: 40px;
    /* Adjust as needed */
    left: 40px;
    /* Adjust as needed */
    color: white;
    /* Adjust the color as needed */
    padding: 10px;
    /* Adjust padding as needed */
}

.about-top-text h1 {
    margin: 0;
    font-size: 4em;
    color: #FFFFFF;
    /* Adjust as needed */
    font-family: 'Times New Roman';
}

.about-top-text p {
    margin: 5px 0 0;
    font-size: 2em;
    /* Adjust as needed */
}

/* About Content Section Styles */
.about-content-section {
    display: flex;
    padding: 20px 20px;
    margin-top: 30px;
    justify-content: space-between;
}

.left-column {
    flex: 1;
    padding-right: 20px;
    /* Adds space between columns */
}

.left-column h1 {
    font-size: 3em;
    margin-bottom: 20px;
    font-family: 'Times New Roman';
}

.left-column p {
    font-size: 1.2em;
    margin-bottom: 20px;
    line-height: 1.5;
    color: #808080;
}

.right-column {
    flex: 1;
    padding-right: 20px;
}

.right-column-image {
    width: 550px;
    height: auto;
    border-radius: 10px;
    /* Optional: adds rounded corners to the image */
}

/* Journey Section Styles */
.journey-section {
    display: flex;
    padding: 20px;
    margin-top: 20px;
    border: 2px solid #ccc;
    margin-bottom: 20px;
    background-color: #BDB76B;
    height: 100px;
}

.journey-section h1 {
    text-align: left;
    font-size: 3em;
    margin-left: 20px;
    font-family: 'Times New Roman';
}

/* Story Section Styles */
.story-section {
    display: flex;
    padding: 40px 20px;
    margin-top: 40px;
    padding-bottom: 60px;
}

.story-left-column {
    flex: 1;
    padding-right: 20px;
}

.story-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-left: 20px;
}

.story-right-column {
    flex: 1;
    padding-left: 20px;
}

.story-right-column p {
    font-size: 1.2em;
    margin-bottom: 20px;
    line-height: 1.5;
    color: #808080;
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
    .about-top-image {
        height: 300px;
        width: 100%;
    }

    .about-top-text {
        bottom: 20px;
        /* Adjust position */
        left: 20px;
        /* Adjust position */
    }

    .about-top-text h1 {
        font-size: 2.5em;
        /* Reduce font size */
    }

    .about-top-text p {
        font-size: 1em;
        /* Reduce font size */
    }

    .about-content-section {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        padding-top: 0%;
        margin-top: 0%;
    }

    .left-column,
    .right-column {
        padding: 10px;
        max-width: 100%;
    }

    .left-column {
        margin-bottom: 20px;
    }

    .right-column-image {
        width: 100%;
        height: auto;
    }

    .journey-section {
        height: auto;
        padding: 10px;
    }

    .journey-section h1 {
        font-size: 2em;
        text-align: center;
    }

    .story-section {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 0%;
        margin-top: 0%;
    }


    .story-right-column {
        padding: 10px;
        max-width: 100%;
    }

    .story-left-column {
        padding: 10px;
        max-width: 100%;
        margin-right: 30px;
    }

    .story-right-column p {
        font-size: 1em;
    }
}