/* src/pages/Footer.css */

footer {
    background-size: cover;
    background-position: center;
    position: relative;
    color: #fff;
    height: 400px;
    margin: 0;
    /* Remove margin */
    padding: 0;
    /* Remove padding */
}

.footer-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    height: calc(100% - 40px);
}

.left-section {
    max-width: 33%;
    margin-left: 15px;
}

.left-section h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #fff;
}

.social-media {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.social-media a {
    color: #fff;
    font-size: 20px;
}

.contact-info p {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.contact-info i {
    margin-right: 10px;
}


.right-section {
    max-width: 33%;
    margin-right: 15px;
}

.center-section {
    max-width: 33%;

}

.center-section ul,
.right-section ul {
    list-style: none;
    color: white;
    padding: 0;
}

.center-section ul li a,
.right-section ul li a {
    color: #fff;
    text-decoration: none;
}

.center-section li,
.right-section li {
    margin: 20px 0;
    font-size: 1.5em;
    color: white;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #444;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    margin: 0;
    /* Ensure no margin */
    font-family: 'Times New Roman', Times, serif;
}

.footer-bottom a {
    color: white;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;

    }

    .left-section,
    .center-section,
    .right-section {
        max-width: 100%;
        text-align: center;
        margin: 0 15px;
        /* Add some margin for spacing */
    }


    .footer-bottom {
        flex-direction: column;
        /* Stack items vertically */
    }


    .footer-bottom p {
        display: flex;

        margin-bottom: 0%;
        /* Add some margin for spacing */
        width: 100%;
        /* Ensure it takes full width */
        text-align: center;
        /* Center text for better readability */

        text-align: center;
        margin-left: 20px;
    }

    .left-section {
        margin-top: 5px;
    }

    .left-section h1 {
        font-size: 1.2em;
    }

    .social-media {
        margin-top: 5px;
        margin-left: 25px;
    }

    .contact-info p,
    i {
        font-size: 1em;
        margin-bottom: 2px;
        /* Ensure some minimal spacing */
        margin-top: 2px;
        margin-left: 20px;
    }

    .center-section {
        margin-top: 10px;
        /* Add margin for spacing */
    }

    .center-section li {
        font-size: 1em;
        margin-bottom: 2px;
        /* Ensure some minimal spacing */
        margin-top: 2px;
    }

    .right-section li {
        font-size: 1em;
        margin-top: 2px;
        margin-bottom: 2px;
        /* Ensure some minimal spacing */
    }

    .right-section {
        margin-top: 10px;
        /* Add margin for spacing */
        margin-bottom: 15px;
        /* Add margin for spacing */
    }
}