.welcome-section,
.benefits-section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-top: 20px;
}

.left-column {
    flex: 1;
    padding: 20px;
    text-align: left;
    max-width: 65%;
}

.left-column h1 {
    font-size: 3em;
    margin-bottom: 10px;
}

.left-column p {
    font-size: 1.2em;
    line-height: 1.5;
}

.left-column ul {
    list-style-type: square;
    padding: 0;
    font-size: 1.2em;
}

.left-column ul li {
    margin-bottom: 10px;
    line-height: 1.5;
}

.right-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.right-column img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

/* Remove margin from the last image */
.right-column img:last-child {
    margin-bottom: 0;
}

.Cinnamon {
    display: flex;
    padding: 20px;
    margin-top: 20px;
    border: 2px solid #ccc;
    margin-bottom: 20px;
    background-color: #BDB76B;
}

.Cinnamon h1 {
    text-align: left;
    font-size: 3em;
    margin-left: 20px;
    font-family: 'Times New Roman', Times, serif;
}

.benefits-section .left-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.benefits-section .left-column img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.benefits-section .right-column {
    flex: 1;
    padding: 20px;
    text-align: left;
    max-width: 65%;
}

.benefits-section .right-column h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    font-family: 'Times New Roman', Times, serif;
}

.benefits-section .right-column p {
    font-size: 1.2em;
    line-height: 1.5;
    color: #808080;
}

.benefits-section .right-column ul {
    list-style-type: square;
    padding: 0;
    font-size: 1.2em;
    color: #808080;
}

.benefits-section .right-column ul li {
    margin-bottom: 10px;
    line-height: 1.5;
}

.worldwide-section {

    margin-top: 20px;

    margin-bottom: 20px;

}

.worldwide-section h1 {
    text-align: left;
    font-size: 3em;
    margin-left: 20px;
    font-family: 'Times New Roman', Times, serif;

}

.image-section {
    margin-top: 50px;
    position: relative;
    width: 100%;
}

.full-width-image {
    width: 100%;
    height: 700px;
    object-fit: cover;
    filter: blur(5px);
}

.transparent-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* Adjust the size as needed */
    height: auto;
    opacity: 0.5;
    /* Adjust the opacity as needed */
    margin-left: 175px;
}

.four-images-section {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
}

.four-images-section a {
    display: block;
    flex: 1;
    position: relative;
    margin: 0;
    padding: 0;
}

.four-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin-top: 80px;
}

.four-images-section a:hover .four-image {
    transform: scale(1.1);
    /* Scale image on hover */
}


.image-title {
    position: absolute;
    top: 280px;
    left: 10px;
    margin: 0;
    padding: 5px 10px;

    color: white;
    font-size: 3em;
}

.manufacturing-process-section {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
}

.manufacturing-process-section .left-column {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
}

.manufacturing-process-section .left-column h1 {
    font-size: 3em;
    margin-bottom: 10px;
}

.manufacturing-process-section .left-column p {
    font-size: 1.2em;
    line-height: 1.5;
}

.manufacturing-process-section .right-column {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
}

.manufacturing-process-section .left-column .ordered-list-container {
    display: flex;
    flex-wrap: wrap;
}

.manufacturing-process-section .ordered-list-left,
.manufacturing-process-section .ordered-list-right {
    flex: 1;
    margin-right: 20px;
    color: #808080;
}

.manufacturing-process-section .ordered-list-right {
    margin-right: 0;
}

.manufacturing-process-section .ordered-list-left ol,
.manufacturing-process-section .ordered-list-right ol {
    padding-left: 20px;
    font-size: 1em;
}

.manufacturing-process-section .ordered-list-left li,
.ordered-list-right li {
    margin-bottom: 10px;
}

.manufacturing-process-section .manufacturing-video {
    width: 100%;
    height: auto;
    max-height: 600px;
}

.new-section {
    padding: 50px 0;
    background-color: #FDF5E6;
    max-height: 300px;
}

.icon-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
}

.icon-item {
    text-align: center;
    width: 22%;
    margin: 20px 0;
}

.icon-item img {
    width: 50px;
    height: 50px;
}

.icon-item h2 {
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.icon-item p {
    font-size: 18px;
    color: #555;
}

.new-image-section {
    position: relative;
    width: 100%;
    height: 500px;
    /* Adjust the height as needed */
    margin-top: 80px;
}

.full-width-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the section */
}

.rectangle-box {
    position: absolute;
    top: 0;
    /* Align the box to the top */
    left: 0;
    /* Align the box to the left */
    width: 100%;
    height: 500px;
    /* Same height as the container */
    background-color: rgba(0, 0, 0, 0.6);
    /* Semi-transparent background */
    color: #fff;
    /* Text color inside the box */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align items to the start (left) */
    justify-content: flex-start;
    /* Align items to the start (top) */
    padding: 20px;
    box-sizing: border-box;
}

.rectangle-box h1 {
    margin: 0 0 10px;
    font-size: 3em;
    color: white;
}

.rectangle-box p {
    margin: 0;
    font-size: 1.5em;
}

.feedback-cards {
    display: flex;
    justify-content: space-around;
    animation: fadeIn 1s ease-in-out;
    margin-left: 150px;
    margin-top: 50px;
}

.feedback-card {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 300px;
    text-align: center;
    transition: opacity 1s;
    margin-right: 50px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.faq {
    display: flex;
    padding: 20px;
    margin-top: 40px;
    border: 2px solid #ccc;
    margin-bottom: 20px;
    background-color: #BDB76B;
}

.faq h1 {
    text-align: left;
    font-size: 3em;
    margin-left: 20px;
    font-family: 'Times New Roman', Times, serif;
}

.faq-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 60px;
}

.faq-items {
    flex: 1;
    margin-right: 20px;
    max-width: 75%;
}

.faq-item {
    margin-bottom: 20px;
}

.faq-question {
    cursor: pointer;
    font-weight: bold;
    color: #333;
    margin: 0;
    font-size: 1em;
}

.faq-answer {
    padding: 10px 0;
    color: #555;
}

.faq-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.faq-image img {
    max-width: 80%;
    height: auto;
}

.other-companies-section {
    text-align: center;
    padding: 50px 0;
    overflow: hidden;
}

.other-companies-section h1 {
    font-size: 3em;
    margin-bottom: 10px;
    font-family: 'Times New Roman', Times, serif;
}

.other-companies-section p {
    font-size: 1.2em;
    line-height: 1.5;
}

.other-companies-section a {
    color: black;
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 20px;
}

.other-companies-images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px;
    justify-content: center;
    animation: moveImages 20s linear infinite;
    overflow: hidden;
    /* Hide the overflowing images */
    animation-play-state: running;
    /* Default state */
}

.other-companies-images:hover {
    animation-play-state: paused;
    /* Pause animation on hover */
}

.other-companies-images img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

@keyframes moveImages {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}


@media (max-width: 768px) {
    .welcome-section {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        padding-top: 0%;
        margin-top: 0%;
    }

    .left-column {
        padding: 10px;
        /* Adjust padding for mobile devices */
        max-width: 100%;
        /* Make the column full width on mobile devices */
        text-align: center;
        /* Center align text for better readability on mobile */
    }

    .left-column h1 {
        font-size: 2em;
        /* Adjust font size for better readability on mobile */
    }

    .left-column p {
        font-size: 1em;
        /* Adjust font size for better readability on mobile */
    }

    .left-column ul {
        font-size: 1em;
        /* Adjust font size for better readability on mobile */
    }

    .left-column ul li {
        font-size: 1em;
        /* Adjust font size for better readability on mobile */
    }

    .right-column {
        padding: 10px;
        /* Adjust padding for mobile devices */
        align-items: flex-start;
        /* Align items to the start */
    }

    .right-column img {
        margin-bottom: 15px;
        /* Adjust margin for better spacing on mobile */
    }

    .Cinnamon {
        flex-direction: column;
        /* Stack items vertically */
        padding: 10px;
        /* Adjust padding for mobile devices */
        margin-top: 10px;
        /* Adjust margin for mobile devices */
        margin-bottom: 10px;
        /* Adjust margin for mobile devices */
    }

    .Cinnamon h1 {
        text-align: center;
        /* Center align text for better readability on mobile */
        font-size: 2em;
        /* Adjust font size for better readability on mobile */
        margin-left: 0;
        /* Remove left margin for mobile devices */
    }

    .benefits-section {
        flex-direction: column;
        /* Stack items vertically on mobile devices */
        padding: 10px;
        /* Adjust padding for mobile devices */
        margin-top: 10px;
        /* Adjust margin for mobile devices */
    }

    .benefits-section .left-column,
    .benefits-section .right-column {
        max-width: 100%;
        /* Make columns full width on mobile devices */
        padding: 10px;
        /* Adjust padding for mobile devices */
        text-align: center;
        /* Center align text for better readability on mobile */
    }

    .benefits-section .right-column h1 {
        font-size: 2em;
        /* Adjust font size for better readability on mobile */
    }

    .benefits-section .right-column p,
    .benefits-section .right-column ul {
        font-size: 1em;
        /* Adjust font size for better readability on mobile */
    }

    .benefits-section .right-column ul li {
        font-size: 1em;
        /* Adjust font size for better readability on mobile */
    }

    .worldwide-section {
        margin-top: 10px;
        /* Adjust margin for mobile devices */
        margin-bottom: 10px;
        /* Adjust margin for mobile devices */
    }

    .worldwide-section h1 {
        text-align: center;
        /* Center align text for better readability on mobile */
        font-size: 2em;
        /* Adjust font size for better readability on mobile */
        margin-left: 0;
        /* Remove left margin for mobile devices */
    }

    .image-section {
        margin-top: 20px;
        /* Adjust margin for mobile devices */
    }

    .full-width-image {
        height: 200px;
        width: 80%;
        margin-left: 40px;
        /* Adjust height for mobile devices */
    }

    .transparent-image {
        width: 80%;
        height: auto;
        margin-left: 30px;
        /* Remove left margin for mobile devices */
    }

    .four-images-section {
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .four-image {
        height: 200px;
        margin-top: 20px;
    }

    .image-title {
        top: 160px;
        font-size: 2em;
    }

    .manufacturing-process-section {
        flex-direction: column;
    }

    .manufacturing-process-section .left-column,
    .manufacturing-process-section .right-column {
        flex: 1 0 100%;
        padding: 10px;
    }

    .manufacturing-process-section .left-column h1 {
        font-size: 2em;
    }

    .manufacturing-process-section .ordered-list-container {
        flex-direction: column;
    }

    .manufacturing-process-section .ordered-list-left,
    .manufacturing-process-section .ordered-list-right {
        flex: 1 0 100%;
        margin-right: 0;
    }

    .manufacturing-process-section .ordered-list-left ol,
    .manufacturing-process-section .ordered-list-right ol {
        padding-left: 20px;
        font-size: 1em;
    }

    .manufacturing-process-section .ordered-list-left li,
    .manufacturing-process-section .ordered-list-right li {
        margin-bottom: 10px;
    }

    .manufacturing-process-section .manufacturing-video {
        width: 100%;
        height: auto;
        max-height: 400px;
    }

    .new-section {
        padding: 30px 0;
        /* Reduced padding for smaller screens */
        background-color: #FDF5E6;
        /* Keep background color the same */
        max-height: none;
        /* Remove max-height to allow content to fit */
    }

    .icon-container {
        flex-direction: column;
        /* Stack icons vertically */
        align-items: center;
        /* Center items horizontally */
    }

    .icon-item {
        width: 80%;
        /* Adjust width for better fit on smaller screens */
        margin: 15px 0;
        /* Reduced margin for better spacing */
    }

    .icon-item img {
        width: 40px;
        /* Reduced icon size for smaller screens */
        height: 40px;
    }

    .icon-item h2 {
        font-size: 20px;
        /* Smaller font size for headers */
        margin-top: 5px;
        /* Reduced margin for headers */
        margin-bottom: 5px;
    }

    .icon-item p {
        font-size: 16px;
        /* Smaller font size for paragraphs */
        color: #555;
        /* Keep text color the same */
    }

    .feedback-cards {
        flex-direction: column;
        align-items: center;
        margin-left: 0;

        position: relative;
    }

    .feedback-card {
        max-width: none;
        margin-right: 0;
        margin-bottom: 60px;
    }

    .feedback-card:not(:first-child) {
        display: none;
    }

    .rectangle-box h1 {

        font-size: 2em;

    }

    .rectangle-box p {

        font-size: 1.5em;
    }

    .new-image-section {
        height: auto;
        /* Adjust height for mobile view */
    }

    .rectangle-box {
        height: 400px;
        width: 80%;
        margin-left: 40px;
        /* Adjust height for mobile view */
    }

    .faq {
        flex-direction: column;
        padding: 10px;
        margin-top: 250px;
    }

    .faq h1 {
        font-size: 2em;
        margin-left: 0;
        text-align: center;
    }

    .faq-section {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .faq-items {
        margin-right: 0;
        max-width: 100%;
        margin-bottom: 20px;
    }

    .faq-image {
        width: 100%;
        justify-content: center;
        margin-bottom: 20px;
    }

    .faq-image img {
        max-width: 100%;
    }

    .other-companies-images {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(10, auto);
    }

    .other-companies-images img {
        height: auto;
        margin-bottom: 10px;
    }

    .other-companies-section h1 {
        font-size: 2.5em;
        margin-bottom: 10px;
        font-family: 'Times New Roman', Times, serif;
    }

}