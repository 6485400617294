/* OurProducts.css */
.product-top-section {
    position: relative;
}

.product-top-image {
    width: 100%;
    height: 500px;
}

.product-top-text {
    position: absolute;
    bottom: 40px;
    /* Adjust as needed */
    left: 40px;
    /* Adjust as needed */
    color: white;
    /* Adjust the color as needed */
    padding: 10px;
    /* Adjust padding as needed */

}

.product-top-text h1 {
    margin: 0;
    font-size: 4em;
    color: #FFFFFF;
    /* Adjust as needed */
    font-family: 'Times New Roman', Times, serif;
}

.product-top-text p {
    margin: 5px 0 0;
    font-size: 1.8em;
    /* Adjust as needed */
}

.production {
    margin: 40px 0;
    text-align: center;
}

.production h1 {
    font-size: 3em;
    font-family: 'Times New Roman', Times, serif;
}

.products-buttons-section {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 3em;
}

.products-buttons-section button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
}

.products-buttons-section button:hover {
    background-color: #444;
}

.products-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .product-top-section {
        position: relative;
    }

    .product-top-image {
        height: 300px;
        width: 100%;
    }

    .product-top-text {
        bottom: 20px;
        /* Adjust position */
        left: 20px;
        /* Adjust position */
    }

    .product-top-text h1 {
        font-size: 2.5em;
        /* Reduce font size */
    }

    .product-top-text p {
        font-size: 1em;
        /* Reduce font size */
    }

    .production h1 {
        font-size: 2.5em;
    }


}