.contact-top-section {
    position: relative;
}

.contact-top-image {
    width: 100%;
    height: 500px;
}

.contact-top-text {
    position: absolute;
    bottom: 40px;
    /* Adjust as needed */
    left: 40px;
    /* Adjust as needed */
    color: white;
    /* Adjust the color as needed */
    padding: 10px;
    /* Adjust padding as needed */

}

.contact-top-text h1 {
    margin: 0;
    font-size: 4em;
    color: #FFFFFF;
    /* Adjust as needed */
    font-family: 'Times New Roman', Times, serif;
}

.contact-top-text p {
    margin: 5px 0 0;
    font-size: 1.8em;
    /* Adjust as needed */
}

.contact-form-section {
    text-align: center;
    padding: 40px 20px;
    margin-top: 40px;
}

.contact-form-box {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
}

.contact-form-box h2 {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #333;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form label {
    margin-top: 25px;
    /* Adjusted for better spacing */
    font-size: 1.2em;
    width: 100%;
    /* Ensures labels are aligned with inputs */
    text-align: left;
    /* Aligns label text to the left */
}

.contact-form input,
.contact-form textarea,
.contact-form select,
.contact-form ::-webkit-keygen-select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}



.contact-form button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2em;
    background-color: #BDB76B;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #A9A9A9;
}

.location-map-section {
    margin-bottom: 60px;
    margin-top: 60px;
    text-align: center;

}

.map-container {
    max-width: 1200px;
    /* Adjust to fit your layout */
    margin: 0 auto;
    padding: 0 15px;
}

.map-frame {
    width: 100%;
    height: 500px;
    border: 0;
    border-radius: 8px;
    /* Optional: adds rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Optional: adds a shadow effect */
}

/* Mobile styles */
@media (max-width: 768px) {
    .contact-top-section {
        position: relative;
    }

    .contact-top-image {
        height: 300px;
        width: 100%;

    }

    .contact-top-text {
        bottom: 20px;
        /* Adjust position */
        left: 20px;
        /* Adjust position */
    }

    .contact-top-text h1 {
        font-size: 2.5em;
        /* Reduce font size */
    }

    .contact-top-text p {
        font-size: 1em;
        /* Reduce font size */
    }

    .contact-form-section {
        text-align: center;
        padding: 20px 10px;
        /* Reduce padding */
        margin-top: 20px;
        /* Reduce margin */
    }

    .contact-form-box {
        padding: 15px;
        /* Reduce padding */
        max-width: 300px;
        margin-right: 30px;
    }

    .contact-form-box h2 {
        font-size: 1em;
        /* Reduce font size */
    }

    .contact-form label {
        margin-top: 15px;
        /* Reduce margin */
        font-size: 1em;
        /* Reduce font size */
    }

    .contact-form input,
    .contact-form textarea,
    .contact-form select,
    .contact-form ::-webkit-keygen-select {
        font-size: 0.9em;
        /* Reduce font size */
    }

    .contact-form button {
        padding: 10px;
        /* Reduce padding */
        font-size: 1em;
        /* Reduce font size */
    }

    .location-map-section {
        margin-bottom: 40px;
        /* Reduce margin */
        margin-top: 40px;
        /* Reduce margin */
    }

    .map-frame {
        height: 300px;
        /* Reduce height */
    }
}