/* Product.css */
.product-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    margin: 10px;
    flex: 1;
    min-width: 200px;
    margin-left: 80px;
    margin-right: 80px;
}

.product-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.product-card h3 {
    font-size: 1.2em;
    margin: 10px 0;
}

.product-card p {
    color: #777;
    margin-bottom: 10px;
}

.product-card button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.product-card button:hover {
    background-color: #444;
}

/* Media queries for mobile sizes */
@media (max-width: 768px) {
    .product-card {
        padding: 15px;
        margin: 10px;
        margin-left: 20px;
        margin-right: 20px;
        min-width: 100px;
        /* Remove min-width to fit better on small screens */
    }

    .product-card h3 {
        font-size: 1em;
        /* Adjust font size for smaller screens */
    }

    .product-card p {
        font-size: 0.9em;
        /* Adjust font size for smaller screens */
    }

    .product-card button {
        padding: 8px 16px;
        /* Adjust button padding for smaller screens */
        font-size: 0.9em;
        /* Adjust button font size for smaller screens */
    }
}